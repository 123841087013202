import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
`
const Pagination = styled.div`
    display: flex;
    justify-content: center
    max-width: 800px;
    @media (max-width: 1024px) {
        flex-wrap: wrap;
        margin: 0 .5rem;
        justify-content: center;
    }
`
const PaginationItem = styled.div`
    height: 2rem;
    min-width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
    &:hover {
        background-color: #377c9e;
        color: white;
    }
    @media (max-width: 1024px) {
        height: 3rem;
        padding: 0 1rem;
    }
`

const linkStyle = css`
    height: 2rem;
    display: block;
    text-decoration: none;
    color: black;
    @media (max-width: 1024px) {
        height: 3rem;
    }
`

// export default ({ currentPage = 1, numPages = 1, firstUrl = "", pageUrl = "" }) => {
//     return (
//         <PaginationWrapper>
//             <Pagination>
//                 {Array.from({ length: numPages }).map((_, i) => {
//                     return (
//                         <Link
//                             css={linkStyle}
//                             to={i === 0 ? firstUrl : `${pageUrl}${i + 1}`}
//                         >
//                             <PaginationItem key={`nav${i}`}></PaginationItem>
//                             {i + 1}
//                         </Link>
//                     )
//                 })}
//             </Pagination>
//         </PaginationWrapper>
//     )
// }

export default ({
    currentPage = 1,
    totalItems = 0,
    url = "",
    urlFirst = "", // unique first page url
    display = 5,
}) => {
    let pages = []
    let pagesAmount = 0

    if (totalItems && display) {
        pagesAmount = Math.ceil(totalItems / display)

        if (pagesAmount <= 10) {
            for (let i = 1; i <= pagesAmount; i++) {
                pages.push({
                    page: i,
                    url: i === 1 ? urlFirst : url,
                    current: currentPage == i,
                })
            }
        } else {
            if (currentPage <= 6) {
                for (let i = 1; i < 11; i++) {
                    pages.push({
                        page: i,
                        url: i === 1 ? urlFirst : url,
                        current: currentPage == i,
                    })
                }
            } else if (currentPage + 4 >= pagesAmount) {
                for (let i = pagesAmount - 9; i <= pagesAmount; i++) {
                    pages.push({
                        page: i,
                        url: url,
                        current: currentPage == i,
                    })
                }
            } else {
                for (let i = currentPage - 4; i < currentPage + 5; i++) {
                    pages.push({
                        page: i,
                        url: url,
                        current: currentPage == i,
                    })
                }
            }
        }
    }

    const firstUrl = currentPage > 6 && pagesAmount >= 10 ? urlFirst : false

    const lastUrl =
        pagesAmount > 10 && currentPage < pagesAmount
            ? `${url}${pagesAmount}`
            : false

    const nextUrl =
        parseInt(currentPage) + 1 > pagesAmount
            ? false
            : `${url}${currentPage + 1}`

    const prevUrl =
        currentPage - 1 < 1
            ? false
            : currentPage - 1 === 1
            ? urlFirst
            : `${url}${currentPage - 1}`

    return (
        <PaginationWrapper>
            <Pagination>
                {firstUrl && (
                    <Link to={firstUrl} css={linkStyle}>
                        <PaginationItem>Первая</PaginationItem>
                    </Link>
                )}
                {prevUrl && (
                    <Link to={prevUrl} css={linkStyle}>
                        <PaginationItem>Предыдущая</PaginationItem>
                    </Link>
                )}
                {pages.length > 1 &&
                    pages.map((item, i) => (
                        <Link
                            css={linkStyle}
                            key={i}
                            to={
                                item.page === 1
                                    ? urlFirst
                                    : `${item.url}${item.page}`
                            }
                        >
                            <PaginationItem
                                css={css`
                                    background-color: ${item.current
                                        ? "black"
                                        : "inherit"};
                                    color: ${item.current
                                        ? "white"
                                        : "inherit"};
                                `}
                            >
                                {item.page}
                            </PaginationItem>
                        </Link>
                    ))}
                {nextUrl && (
                    <Link css={linkStyle} to={nextUrl}>
                        <PaginationItem>Следующая</PaginationItem>
                    </Link>
                )}
                {lastUrl && (
                    <Link to={lastUrl} css={linkStyle}>
                        <PaginationItem>Последняя</PaginationItem>
                    </Link>
                )}
            </Pagination>
        </PaginationWrapper>
    )
}
