import React from "react"
import { graphql } from "gatsby"
import Container from "../components/layout/Container"
import Header from "../components/Header"
import Space from "../components/Space"
import Content from "../components/layout/Content"
import ContentListItem from "../components/ContentListItem"
import Pagination from "../components/Pagination"
import { getVideoContentItemData } from "../utils"
import { Helmet } from "react-helmet"
import Title from "../components/Title"
import TrainingBanner from "../components/TrainingBanner"

export default props => {
    const { edges } = props.data.allMongodbVpletaysyaVideos
    const { pageContext } = props

    return (
        <Container>
            <Helmet>
                <title>Косички - видео уроки</title>
                <meta
                    name="keywords"
                    content="косички видео, прически видео, видео уроки косички, видео уроки прически, видео плетения косичек"
                />
                <meta
                    name="description"
                    content="Видео уроки по плетению косичек и причесок из косичек"
                />
            </Helmet>
            <Header />
            <TrainingBanner />
            <Space />
            <Content>
                <Title>Видео-уроки</Title>
                {edges.map((item, i) => (
                    <ContentListItem
                        key={`lessons_${i}`}
                        {...getVideoContentItemData(item)}
                    />
                ))}
                <Pagination
                    currentPage={pageContext.currentPage}
                    totalItems={pageContext.totalItems}
                    display={pageContext.limit}
                    urlFirst="/videolesson"
                    url="/videolesson/page/"
                />
            </Content>
            <Space />
        </Container>
    )
}

export const blogListQuery = graphql`
    query videosListQuery($skip: Int!, $limit: Int!) {
        allMongodbVpletaysyaVideos(
            sort: { fields: [date], order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    date
                    description
                    id
                    title
                    old_id
                    old_link
                    player
                    text
                }
            }
        }
    }
`
